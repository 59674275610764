import React, { Component } from "react";
import "./Welcome.scss";
import { IProps as IFooterProps } from "../../components/question-footer/QuestionFooter";
import { Constants } from "../../utils/Constants";
import { LoadingView } from "../loading/LoadingView";
import { ServiceController } from "../../controllers/ServiceController";
import { Service } from "../../models/Service";
import FlagIcon from "../../components/flag/FlagComponent";
import { Language } from "../../models/Language";
import { TaxonomyController } from "../../controllers/TaxonomyController";
import { LanguageController } from "../../controllers/LanguageController";
import { compareStrings } from "../../utils/Utils";

interface IState {
    services: Service[];
    selected?: Service;
    languages: { key: string; name: string; flagUrl?: string }[];
    showAllLanguages: boolean;
    selectedLanguage?: Language;
    isConsentChecked: any
}

export class Welcome extends Component<{}, IState> {
    serviceController = new ServiceController();
    taxonomyController = new TaxonomyController();
    languageController = new LanguageController();

    constructor(props: {}) {
        super(props);
        this.state = {
            services: [],
            languages: [],
            showAllLanguages: false,
            selectedLanguage: undefined,
            isConsentChecked: false
        };
    }

    componentDidMount() {
        // Resetting localStorage values
        localStorage.removeItem(Constants.KEY_ANSWERS);
        localStorage.removeItem(Constants.KEY_START);
        localStorage.removeItem(Constants.KEY_CATEGORY);
        localStorage.removeItem(Constants.BLIND_MODE);

        // Fetching services and languages
        this.serviceController.getServices().then((res) => {
            this.setState({ services: res });
        });

        Promise.all([this.taxonomyController.getFlags(), this.languageController.get()]).then(res => {
            let flags: any = res[0];
            let languages = Object.keys(res[1].data.languages).map(key => new Language(key, res[1].data.languages[key]));

            flags.forEach((flag: any) => {
                let language = languages.find(l => l.key === flag.key);
                if (language) {
                    language.flagUrl = flag.value;
                }
            });

            // Set French as the default language if it exists
            const defaultLanguage = languages.find(lang => lang.key === "fr") || languages[0];
            this.setState({
                languages,
                selectedLanguage: defaultLanguage
            });

            if (defaultLanguage) {
                localStorage.setItem(Constants.KEY_LANGUAGE, defaultLanguage.key);
                localStorage.setItem(Constants.NAME_LANGUAGE, defaultLanguage.name);
                localStorage.setItem(Constants.KEY_START, new Date().toLocaleString());
            }
        });

        // if (!localStorage.getItem(Constants.KEY_FIRST_QUESTION)) {
        //     let firstQuestionId = "5ce09476-b23a-404a-859e-17c5bea91080";
        //     this.serviceController.getServices().then(res => {
        //         if (res) {
        //             let service = res.find((s: any) => compareStrings(window.location.pathname, s.name));
        //             if (service) {
        //                 firstQuestionId = service.firstQuestionId;
        //             }
        //         }
        //         localStorage.setItem(Constants.KEY_FIRST_QUESTION, firstQuestionId);
        //     })
        // }
    }
    handleBackNavigation = () => {
        const serviceSelected = localStorage.getItem("SERVICE_SELECTED");
        if (serviceSelected) {
            const service = JSON.parse(serviceSelected);
            if (service && service.name) {
                window.location.href = `/service/${service.name}`;
            }
        }
    }

    handleNextNavigation = () => {

        if (!this.state.isConsentChecked) {
            alert("Veuillez accepter les conditions avant de continuer.");
            return;
        }

        const firstQuestionId = localStorage.getItem(Constants.KEY_FIRST_QUESTION);
        if (firstQuestionId) {
            window.location.href = `/question/${firstQuestionId}`;
        }
    }

    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isConsentChecked: event.target.checked });
    }


    render() {
        const { selectedLanguage } = this.state;

        return (
            <div className="page">
                <div className="welcome">

                    {/* Welcome message */}
                    <h1>Bienvenu.e.s sur</h1>
                    <img src={process.env.PUBLIC_URL + "/logo_marti.svg"} alt="Marti logo" style={{ width: 60, margin: "10px auto" }} />
                    <p>Votre assistant personnel pour commencer à expliquer ce qui vous amène ici.</p>

                    {/* Navigation Instructions */}
                    <div className="navigation-instructions">
                        <h3>Naviguez avec les flèches</h3>
                        <div className="navigation-buttons">
                            <button className="nav-button">←</button>
                            <div>QUESTION PRÉCÉDENTE</div>
                            <button className="nav-button">→</button>
                            <div>QUESTION SUIVANTE</div>
                        </div>
                    </div>

                    {/* Help message */}
                    <div className="help-message">
                        <p>Si vous ne savez pas répondre, cliquez simplement sur le bouton :</p>

                        <div className={'btn-idk'}>
                            <img src={process.env.PUBLIC_URL + "/pictos/shrug.png"} alt="Help icon" style={{ width: 60 }} />
                        </div>
                    </div>

                    {/* Consent checkbox */}
                    <div className="consent-checkbox">
                        <input type="checkbox" id="consent" checked={this.state.isConsentChecked}
                               onChange={this.handleCheckboxChange} />
                        <label htmlFor="consent">
                            Je consens à la collecte et au traitement de données à caractère personnel et je déclare accepter les <a href="/terms">conditions générales</a>.
                        </label>
                    </div>

                    {/* Footer navigation arrows */}
                    <div className="footer-navigation">
                        <button className="footer-nav-button" onClick={this.handleBackNavigation}>←</button>
                        <button className="footer-nav-button" onClick={this.handleNextNavigation}>→</button>
                    </div>
                </div>
            </div>
        );
    }

}
