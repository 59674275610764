import React, { Component } from "react";
import "./Homepage.scss";
import { IProps as IFooterProps } from "../components/question-footer/QuestionFooter";
import { Constants } from "../utils/Constants";
import { LoadingView } from "./loading/LoadingView";
import { ServiceController } from "../controllers/ServiceController";
import { Service } from "../models/Service";
import FlagIcon from "../components/flag/FlagComponent";
import {Language} from "../models/Language";
import {TaxonomyController} from "../controllers/TaxonomyController";
import {LanguageController} from "../controllers/LanguageController";
import {compareStrings} from "../utils/Utils";

interface IState {
    services: Service[];
    selected?: Service;
    footer: IFooterProps;
    languages: { key: string; name: string; flagUrl?: string }[];
    showAllLanguages: boolean;
    selectedLanguage: any;
}

export class HomePage extends Component<{}, IState> {
    serviceController = new ServiceController();
    taxonomyController = new TaxonomyController();
    languageController = new LanguageController();

    constructor(props: {}) {
        super(props);
        this.state = {
            selectedLanguage: undefined,
            showAllLanguages: false,
            services: [],
            footer: {
                visible: true,
                previous: false,
                handlePrevious: null,
                next: false,
                handleNext: this.onNextClick.bind(this)
            },
            languages: [] // Initialize languages as an empty array
        };
    }

    componentDidMount() {
        // Resetting localStorage values
        localStorage.removeItem(Constants.KEY_ANSWERS);
        localStorage.removeItem(Constants.KEY_START);
        localStorage.removeItem(Constants.KEY_FIRST_QUESTION);
        localStorage.removeItem(Constants.KEY_CATEGORY);
        localStorage.removeItem(Constants.BLIND_MODE);

        // Fetching services
        this.serviceController.getServices().then((res) => {
            this.setState({ services: res });
        });

        Promise.all([this.taxonomyController.getFlags(), this.languageController.get()]).then(res => {
            let flags : any = res[0];
            let languages = Object.keys(res[1].data.languages).map(key => new Language(key, res[1].data.languages[key]));


            flags.forEach((flag: any) => {
                let language = languages.find(l => l.key === flag.key);
                if (language) {
                    language.flagUrl = flag.value;
                }
            });
            // Set French as the default language if it exists
            const defaultLanguage: any = languages.find(lang => lang.key === "fr") || languages[0];

            this.setState({
                languages,
                selectedLanguage: defaultLanguage
            });

            if (defaultLanguage) {
                localStorage.setItem(Constants.KEY_LANGUAGE, defaultLanguage.key);
                localStorage.setItem(Constants.NAME_LANGUAGE, defaultLanguage.name);
                localStorage.setItem(Constants.KEY_START, new Date().toLocaleString());
            }

        });


        let firstQuestionId = "5ce09476-b23a-404a-859e-17c5bea91080";
        this.serviceController.getServices().then(res => {
            if (res) {
                let service = res.find((s: any) => compareStrings(window.location.pathname, s.name));
                console.log(service);
                if (service) {
                    firstQuestionId = service.firstQuestionId;
                    localStorage.setItem(Constants.KEY_FIRST_QUESTION, firstQuestionId);
                }
            }

        })



    }

    toggleShowAllLanguages = () => {
        this.setState((prevState : any) => ({ showAllLanguages: !prevState.showAllLanguages }));
    }

    nextStep = () => {
        window.location.href = '/welcome';
    }

    handleClickLanguage = (language_key: string, language_name: string, selectedLanguage: any) => {
        this.setState({ selectedLanguage }); // Update selected language state
        localStorage.setItem(Constants.KEY_LANGUAGE, language_key);
        localStorage.setItem(Constants.NAME_LANGUAGE, language_name);
        localStorage.setItem(Constants.KEY_START, new Date().toLocaleString());
    }

    render() {
        if (this.state.services === null) {
            return <LoadingView />;
        } else {

            const currentLanguageFlag = this.state.selectedLanguage ? (
                <div className="current-language-flag">
                    {this.state.selectedLanguage.flagUrl ? (
                        <img
                            src={this.state.selectedLanguage.flagUrl}
                            alt={this.state.selectedLanguage.key}
                            style={{ width: 40, height: 26 }}
                        />
                    ) : (
                        <FlagIcon
                            code={
                                this.state.selectedLanguage.key.includes("-")
                                    ? this.state.selectedLanguage.key.split("-")[1].toLowerCase()
                                    : this.state.selectedLanguage.key === "en"
                                        ? "gb"
                                        : this.state.selectedLanguage.key
                            }
                        />
                    )}
                </div>
            ) : null;

            // Rendering languages
            const displayedLanguages = this.state.showAllLanguages
                ? this.state.languages
                : this.state.languages.slice(0, 4);
            const languages = displayedLanguages.map((language) => {
                const flag = language.flagUrl ? (
                    <div className="flag-wrapper-s" onClick={() => this.handleClickLanguage(language.key, language.name, language)}>
                        <img src={language.flagUrl} alt={language.key}  style={{width : 40, height: 26}}  />
                    </div>
                ) : (
                    <div onClick={() => this.handleClickLanguage(language.key, language.name, language)} style={{ cursor: 'pointer' }}>
                    <FlagIcon
                        code={
                            language.key.includes("-")
                                ? language.key.split("-")[1].toLowerCase()
                                : language.key === "en"
                                    ? "gb"
                                    : language.key
                        }
                    />
                    </div>
                );

                return (
                    <div key={language.key}  style={{width : 40, padding:20, background: "white"}}>
                        <div style={{background: "white"}}>
                            {flag}
                        </div>

                    </div>
                );
            });

            return (
                <div className="page page-homepage">
                    <div className="homepage">
                        <div className="service-content service-checkboxes">

                            <div style={{margin: 'auto'}}>
                                {currentLanguageFlag}
                                <h1 style={{ fontSize: 40 }}>C'est parti</h1>
                                <div style={{marginLeft: "auto", marginRight: "auto",backgroundColor: "#F1F1F1", width: 70}} onClick={this.nextStep}>
                                    <img
                                        src={process.env.PUBLIC_URL + "/pictos/fleche-nobg.svg"}
                                        width={50}
                                        height={50}
                                        style={{ transform: "rotate(180deg)" }}
                                    />
                                </div>
                            </div>


                        </div>
                        {/* Displaying languages */}
                        <div className="languages-hp">
                            {languages}
                            {this.state.languages.length > 4 && (
                                <button
                                    className="show-more"
                                    onClick={this.toggleShowAllLanguages}
                                >
                                    {this.state.showAllLanguages ? "-" : "+"}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }

    private onNextClick() {
        if (this.state.selected?.firstQuestionId) {
            localStorage.setItem(Constants.KEY_CATEGORY, this.state.selected.id);
            localStorage.setItem(Constants.KEY_FIRST_QUESTION, this.state.selected?.firstQuestionId);
            localStorage.setItem("SERVICE_SELECTED", JSON.stringify(this.state.selected));
            window.location.href = this.state.selected.name;
        }
    }
}
